<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="promocodes-start-mai box-flex">
      <div class="container review-main text-capitalize position-relative box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card | d-flex flex-column">
              <div class="center-card-box | p-3 border-bottom">
                <div class="resturant-details">
                  <h4 class="text-start">{{ $t('Promocode') }}</h4>
                </div>
              </div>
              <div class="center-card-box | p-3 flex-grow-1 d-flex flex-column">
                <!----------------------->
                <div class="orders-tab-main" v-if="isSkeletonLoader">
                  <div class="order-item d-flex align-items-end justify-content-between mb-2 pb-2">
                    <div class="d-flex align-items-start flex-grow-1">
                      <skeleton-loader-vue height="120px" width="120px" class="img-cls"
                        style="border-radius: 5px !important" />
                      <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                        <div class="orders-status-main flex-grow-1">
                          <h6 class="mb-0"><skeleton-loader-vue type="string" width="24%" height="20px" /></h6>
                          <p class="mt-1"><skeleton-loader-vue type="string" width="24%" height="20px" /></p>
                          <div class="promocode mt-2">
                            <p><skeleton-loader-vue type="string" width="36%" height="20px" /></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div><skeleton-loader-vue width="89%" height="31px"
                        style="border-radius: 30px !important;margin-right: 86px;" /></div>
                  </div>
                </div>
                <!----------------------->
                <div class="orders-tab-main" v-if="promoCode.length > 0">
                  <div id="ongoing" class="tab-pane active tab-box">
                    <div class="orders-lst">
                      <div class="order-item d-flex align-items-end justify-content-between mb-2 pb-2"
                        v-for="(offers, index) in promoCode" :key="index">
                        <div class="d-flex align-items-start">
                          <div class="img-cls smallimg-squre">
                            <img :src="offers.coupon_image" />
                          </div>
                          <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                            <div class="orders-status-main">
                              <h6 class="mb-0">{{ offers.coupon_name }}</h6>
                              <p class="mt-1" :id="'shortdec' + offers.id" style="display:block;">
                                {{ (offers.description.substring(0, 54)) }}
                                <a v-if="offers.description && offers.description.length > 54" href="javascript:void(0)"
                                  @click="readMore(offers.id)" class="readmore">...More</a>
                              </p>
                              <p :id="'moredesc' + offers.id" style="display:none;">{{ offers.description }}
                                <a href="javascript:void(0)" @click="readMore(offers.id)" class="readless">Less</a>
                              </p>
                              <div class="promocode mt-2">
                                <p>{{ $t('Code') }}: <span class="m-promocode">{{ offers.coupon_code }}</span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-inline-flex">
                          <a href="javascript:void(0)" class="cart-btn" @click="doCopy(offers.coupon_code)">{{ $t('Copy')
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="home-res-not-found my-auto">
                  <div class="nostr-img-cls" v-if="!loading">
                    <!-- <img src="@/assets/images/no_promocode.png" /> -->
                    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="24" y="130" width="7" height="1" rx="0.5" fill="#FF8300" />
                      <rect x="34" y="130" width="86" height="1" rx="0.5" fill="#FF8300" />
                      <rect x="122" y="130" width="4" height="1" rx="0.5" fill="#FF8300" />
                      <path
                        d="M80 84.5H106C108.485 84.5 110.5 86.5147 110.5 89V130.5H75.5V89C75.5 86.5147 77.5147 84.5 80 84.5Z"
                        stroke="#FF8300" />
                      <path
                        d="M47 58.5H93C95.4853 58.5 97.5 60.5147 97.5 63V130.5H42.5V63C42.5 60.5147 44.5147 58.5 47 58.5Z"
                        fill="white" stroke="#FF8300" />
                      <line x1="34.3537" y1="49.7104" x2="40.8333" y2="56.19" stroke="#FF8300" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <line x1="46.8974" y1="44.6124" x2="44.5257" y2="53.4636" stroke="#FF8300" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <line x1="0.5" y1="-0.5" x2="9.66346" y2="-0.5"
                        transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 28 62.5439)" stroke="#FF8300"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <line x1="51.5" y1="91.5" x2="75.5" y2="91.5" stroke="#FF8300" stroke-width="3"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <line x1="51.5" y1="101.5" x2="65.5" y2="101.5" stroke="#FF8300" stroke-width="3"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <line x1="51.5" y1="83.5" x2="84.5" y2="83.5" stroke="#FF8300" stroke-width="3"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <rect x="102" y="65" width="19" height="1" rx="0.5" fill="#FF8300" />
                      <rect x="12" y="95" width="19" height="1" rx="0.5" fill="#FF8300" />
                      <rect x="109" y="77" width="19" height="1" rx="0.5" fill="#FF8300" />
                      <circle cx="137.828" cy="107.828" r="2.32787" stroke="#FF8300" />
                      <circle cx="77.8279" cy="47.8279" r="2.32787" stroke="#FF8300" />
                      <circle cx="127.828" cy="47.8279" r="2.32787" stroke="#FF8300" />
                      <circle cx="17.8279" cy="117.828" r="2.32787" stroke="#FF8300" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.1986 70.3995C15.9777 70.1787 15.6196 70.1787 15.3988 70.3995L13.7991 71.9992L12.1994 70.3995C11.9785 70.1787 11.6204 70.1787 11.3995 70.3995C11.1787 70.6204 11.1787 70.9785 11.3995 71.1994L12.9992 72.7991L11.3995 74.3987C11.1787 74.6196 11.1787 74.9777 11.3995 75.1986C11.6204 75.4194 11.9785 75.4194 12.1994 75.1986L13.7991 73.5989L15.3988 75.1986C15.6196 75.4194 15.9777 75.4194 16.1986 75.1986C16.4195 74.9777 16.4195 74.6196 16.1986 74.3987L14.5989 72.7991L16.1986 71.1994C16.4195 70.9785 16.4195 70.6204 16.1986 70.3995Z"
                        fill="#FF8300" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M56.6678 34.6119C56.8346 34.3478 56.7558 33.9985 56.4918 33.8317L54.5793 32.6232L55.7877 30.7107C55.9546 30.4467 55.8758 30.0973 55.6117 29.9305C55.3477 29.7636 54.9984 29.8424 54.8315 30.1065L53.623 32.019L51.7106 30.8105C51.4465 30.6437 51.0972 30.7225 50.9303 30.9865C50.7635 31.2506 50.8423 31.5999 51.1063 31.7667L53.0188 32.9752L51.8104 34.8877C51.6435 35.1518 51.7223 35.5011 51.9864 35.6679C52.2504 35.8348 52.5997 35.756 52.7666 35.4919L53.9751 33.5794L55.8875 34.7879C56.1516 34.9548 56.5009 34.876 56.6678 34.6119Z"
                        fill="#FF8300" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M143.787 71.3934C143.787 71.0811 143.534 70.8279 143.221 70.8279L140.959 70.8279L140.959 68.5656C140.959 68.2532 140.706 68 140.393 68C140.081 68 139.828 68.2532 139.828 68.5656L139.828 70.8279L137.566 70.8279C137.253 70.8279 137 71.0811 137 71.3934C137 71.7058 137.253 71.959 137.566 71.959L139.828 71.959L139.828 74.2213C139.828 74.5337 140.081 74.7869 140.393 74.7869C140.706 74.7869 140.959 74.5337 140.959 74.2213L140.959 71.959L143.221 71.959C143.534 71.959 143.787 71.7058 143.787 71.3934Z"
                        fill="#FF8300" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M108.787 21.3934C108.787 21.0811 108.534 20.8279 108.221 20.8279L105.959 20.8279L105.959 18.5656C105.959 18.2532 105.706 18 105.393 18C105.081 18 104.828 18.2532 104.828 18.5656L104.828 20.8279L102.566 20.8279C102.253 20.8279 102 21.0811 102 21.3934C102 21.7058 102.253 21.959 102.566 21.959L104.828 21.959L104.828 24.2213C104.828 24.5337 105.081 24.7869 105.393 24.7869C105.706 24.7869 105.959 24.5337 105.959 24.2213L105.959 21.959L108.221 21.959C108.534 21.959 108.787 21.7058 108.787 21.3934Z"
                        fill="#FF8300" />
                    </svg>
                    <h6>{{ $t('No Offers Available') }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import NavBar from "../components/NavBar.vue";
import Footer from '../components/Footer.vue';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  components: { NavBar, Footer, 'skeleton-loader-vue': VueSkeletonLoader },
  data() {
    return {
      loading: true,
      promoCode: [],
      isAlert: false,
      isSelectCoupon: '',
      isSkeletonLoader: true,
    };
  },
  mounted() {
    this.getPromoCode();
  },
  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),
  },
  methods: {
    ...mapActions("promocode", ["updatePromoCode"]),

    getPromoCode() {
      let deliveryType = 'Delivery';
      if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
        deliveryType = 'Delivery';
      } else {
        deliveryType = 'Pickup';
      }
      let usrid = '';
      if (this.$auth.user && this.$auth.user.user_id) {
        usrid = this.$auth.user.user_id;
      }
      this.loading = true;
      this.isSkeletonLoader = true;
      this.updatePromoCode({
        vendor_id: this.$auth.getVendorId(),
        coupon_type: deliveryType,
        user_id: usrid,
        is_langauge: this.$store.state.lang.locale,
        cityname: localStorage.getItem("city"),
        latitude: this.$auth.setting.system_latitude,
        longitude: this.$auth.setting.system_longitude,


      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          this.isSkeletonLoader = false;
          this.promoCode = data.Result;
        } else {
          this.loading = false;
          this.isSkeletonLoader = false;
        }
      });
    },

    doCopy(el) {
      this.isSelectCoupon = el;
      this.$copyText(this.isSelectCoupon);
      this.$toasted.success(this.$t("Promocode Copied to clipboard."), { duration: 2000 });
    },
    readMore(value) {
      $("#moredesc" + value).toggle(400);
      $("#shortdec" + value).toggle(400);
    }
  },
};
</script>
<style scoped>
.nostr-img-cls img {
  width: 30%;
}

.orders-status-main p {
  width: 100% !important;
}
</style>